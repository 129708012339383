<ng-container *ngIf="isCompared(); else isNotCompare">
  <a *ngIf="!showAsButton" (click)="toggleCompare()" class="text-xl hover:text-primary flex items-center relative">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-5" fill="none" viewBox="0 0 24 24" stroke="black"
         stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
    </svg>
  </a>

  <button
    *ngIf="showAsButton"
    (click)="toggleCompare()"
    class="text-primary hover:text-secondary mr-4 last-of-type:mr-0 md:mr-0"
  >
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="black"
         stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
    </svg>
  </button>
</ng-container>

<ng-template #isNotCompare>
  <a *ngIf="!showAsButton" (click)="toggleCompare()" class="text-xl hover:text-primary flex items-center relative">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"
         stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
    </svg>
  </a>

  <button *ngIf="showAsButton" (click)="toggleCompare()"
          class="text-primary hover:text-secondary mr-4 last-of-type:mr-0 md:mr-0">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"
         stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"></path>
    </svg>
  </button>
</ng-template>
