import { ChangeDetectionStrategy, Component, computed, inject, input, Input } from '@angular/core';
import { Article } from '@lobos/common-v3';
import { AsyncPipe, NgIf } from '@angular/common';
import { GlobalStore } from '../../../store/global.store';

@Component({
  selector: 'app-article-compare-item',
  templateUrl: './article-compare-item.component.html',
  styleUrls: ['./article-compare-item.component.scss'],
  standalone: true,
  imports: [NgIf, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleCompareItemComponent {
  article = input.required<Article>();
  @Input() showAsButton: boolean = false;

  store = inject(GlobalStore);

  isCompared = computed(() => this.store.compareItems().some((item: Article) => item.sArticleID === this.article().sArticleID));

  toggleCompare() {
    if (!this.isCompared()) {
      this.store.addCompareItem(this.article());
    } else {
      this.store.removeCompareItem(this.article());
    }
  }
}
